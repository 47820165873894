import React from "react";
import { Router } from "@reach/router";

import { ClientOnly } from "../../components";
import { ProtectedRoute } from "../../core/auth";

import Login from "./login";
import LoginCallback from "./login/callback";
import RewardsLink from "./link";

const Rewards = () => (
  <ClientOnly>
    <Router basepath="/rewards">
      <LoginCallback path="/login/callback" />

      <ProtectedRoute
        mode={ProtectedRoute.MODE.CUSTOMER}
        path="/link"
        as={RewardsLink}
        SignInComponent={Login}
      />
    </Router>
  </ClientOnly>
);

export default Rewards;
